@import '../../node_modules/swiper/swiper-bundle.min.css';

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway-Bold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Vetrino";
  src: url("../fonts/vetrino.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  height: 100%;
  -webkit-text-size-adjust: none;
  -webkit-appearance: none;
  max-height: 9001em;
  line-height: 1.3;
  -webkit-text-size-adjust: 100%;
}

body {
  min-height: 100%;
  height: 100%;
  width: 100%;
  min-width: 320px;
  margin: 0;
  font-size: 18px;
  font-family: "Raleway", sans-serif;
  color: #26201a;
  background-color: #f5f4f4;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  backface-visibility: hidden;
  text-rendering: geometricPrecision;
}

h1,
.title-2,
.title-1,
h2,
h3,
.title-3,
h4,
.title-4,
h5,
.title-5,
h6,
.title-6 {
  margin: 0;
  font-weight: normal;
  font-family: "Vetrino", sans-serif;
  line-height: 1.08em;
}

h1,
.title-2,
.title-1,
h2 {
  font-size: 56px;
  line-height: 1.08em;
}

h1 span,
.title-2 span,
.title-1 span,
h2 span {
  color: #ff9556;
}

h3,
.title-3 {
  font-size: 36px;
  margin-bottom: 23px;
}

h4,
.title-4 {
  font-size: 34px;
  margin-bottom: 8px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  line-height: 1.6em;
  margin: 0;
  background-color: transparent;
}

a:visited {
  text-decoration: none;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bold;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img,
picture,
source {
  max-width: 100%;
  width: 100%;
  display: block;
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

input {
  line-height: 1em;
}

button,
input {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  appearance: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: hidden;
  resize: none;
}

textarea:focus {
  outline: none;
}

label {
  cursor: pointer;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

p,
li {
  margin: 0;
  color: inherit;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

section.section {
  position: relative;
  overflow: hidden;
}

tr {
  line-height: 1.5em;
}

figure {
  margin: 0;
}

blockquote {
  margin: 0;
}

button {
  background-color: transparent;
  border: none;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: none;
}

input[type=date] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

input[type=date]::-webkit-inner-spin-button {
  display: none;
}

input[type=date]::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: relative;
  right: -10px;
}

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  font-size: 16px;
  color: #26201a;
}

textarea::-moz-placeholder,
input::-moz-placeholder {
  font-size: 16px;
  color: #26201a;
}

textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  font-size: 16px;
  color: #26201a;
}

textarea::placeholder,
input::placeholder {
  font-size: 16px;
  color: #26201a;
}

input {
  outline: none;
}

.main-wrapper {
  position: relative;
  overflow: hidden;
}

.container {
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 15px;
}

.main,
main {
  display: block;
  position: relative;
}

.locked {
  overflow: hidden;
}

.jarallax {
  position: relative;
  z-index: 0;
}

.global-wrapper {
  height: 100%;
}

.icon-social {
  width: 25px;
  height: 25px;
  transition: fill 0.5s cubic-bezier(0.17, 0.67, 0.55, 1.05);
}

.icon-arrow {
  width: 48px;
  height: 32px;
  stroke: #26201a;
  fill: none;
  transition: stroke 0.5s cubic-bezier(0.17, 0.67, 0.55, 1.05);
}

.btn-more {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: #ff9556;
  padding: 0;
}

.btn-more.active .plus::after {
  transform: translate(-50%, -50%) rotate(95deg);
}

.btn {
  background-color: #ffffff;
  color: #26201a;
  text-align: center;
  display: inline-block;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
  border-radius: 50px;
  font-size: 16px;
  white-space: nowrap;
  transition: background-color 0.5s cubic-bezier(0.17, 0.67, 0.55, 1.05), color 0.5s cubic-bezier(0.17, 0.67, 0.55, 1.05);
}

.btn:hover {
  color: #ff9556;
}

.btn_primary {
  background-color: #ff9556;
  height: 60px;
  line-height: 60px;
  color: #ffffff;
  padding: 0 40px;
  font-size: 18px;
}

.btn_primary:hover {
  color: #ffffff;
}

.plus {
  width: 24px;
  height: 24px;
  background-color: #ff9556;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  margin-right: 9px;
}

.plus::before,
.plus::after {
  content: "";
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.plus::before {
  width: 10px;
  height: 1px;
}

.plus::after {
  width: 1px;
  height: 10px;
  transition: transform 0.5s cubic-bezier(0.17, 0.67, 0.55, 1.05);
}

.plus_lg {
  width: 24px;
  height: 24px;
  position: absolute;
  display: inline-block;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.plus_lg::before,
.plus_lg::after {
  content: "";
  background-color: #ff9556;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.plus_lg::before {
  width: 20px;
  height: 1px;
}

.plus_lg::after {
  width: 1px;
  height: 20px;
  transition: transform 0.5s cubic-bezier(0.17, 0.67, 0.55, 1.05);
}

.anim-top {
  transition: transform 1s cubic-bezier(0.17, 0.67, 0.55, 1.05), opacity 1s cubic-bezier(0.17, 0.67, 0.55, 1.05);
}

.anim-top_lg {
  transition: transform 1s cubic-bezier(0.17, 0.67, 0.55, 1.05), opacity 1s cubic-bezier(0.17, 0.67, 0.55, 1.05);
}

.anim-top_lg.in-view-detect {
  opacity: 0.01;
  transform: translateY(200px);
}

.anim-top_lg.in-view {
  opacity: 1;
  transform: translateY(-63px);
}

.anim-top.in-view-detect {
  opacity: 0.001;
  transform: translateY(40px);
}

.fade-in {
  transition: opacity 1s cubic-bezier(0.17, 0.67, 0.55, 1.05);
}

.fade-in.in-view-detect {
  opacity: 0;
}

.fade-in.in-view {
  opacity: 1;
}

.anim-top.in-view {
  opacity: 1;
  transform: translateY(0);
}

.about__img.in-view-detect {
  opacity: 0;
  transform: translateY(40px);
}

.about__img.in-view {
  opacity: 1;
  transform: translateY(0);
}

.header {
  padding: 40px 0;
}

.header__row {
  max-width: 1340px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
}

.header__logo {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.header__logo p {
  font-weight: 300;
  font-size: 18px;
}

.header__btn {
  position: absolute;
  left: 5px;
  top: 0;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 10;
  font-size: 14px;
}

.header__btn span {
  opacity: 0;
}

.header__btn:hover {
  color: #ff9556;
  transition: 0.5s cubic-bezier(0.17, 0.67, 0.55, 1.05);
}

.header__btn:hover svg {
  stroke: #ff9556;
  transition: stroke 0.5s cubic-bezier(0.17, 0.67, 0.55, 1.05);
}

.header__btn svg {
  opacity: 1 !important;
  stroke: #26201a;
  margin-right: 15px;
}

.logo {
  max-width: 56px;
  margin-bottom: 6px;
}

.lang {
  position: absolute;
  right: 5px;
  top: 0;
  display: flex;
}

.lang__link {
  font-size: 18px;
  position: relative;
  padding: 0 10px;
  font-weight: 300;
}

.lang__link:not(:first-child)::before {
  content: "";
  width: 1px;
  height: 18px;
  background-color: #26201a;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.lang__link.active {
  font-weight: 500;
}

.footer {
  padding: 70px 0 50px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.footer::before {
  content: "";
  width: 100vw;
  height: 100vw;
  border-radius: 50%;
  background-color: #ff9556;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: -1;
}

.footer__social {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.footer__social .cta__links:hover .icon-social {
  fill: #ffffff;
}

.hero {
  padding-top: 4px;
}

.hero__title {
  text-align: center;
  position: relative;
  z-index: 1;
  max-width: 670px;
  margin: 0 auto;
}

.hero__row {
  margin-bottom: 40px;
}

.hero__img {
  max-width: 490px;
  margin: 0 auto;
  position: relative;
  transform: translate(0, -63px);
}

.hero__img img {
  height: 100%;
  aspect-ratio: auto 16/9;
  border-top-right-radius: 300px;
  border-top-left-radius: 300px;
}

.hero__btns {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 490px;
}

.hero__btns.anim-top_lg.in-view {
  opacity: 1;
  transform: translateY(-3px);
}

.hero__btns .btn {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  border-radius: 0;
}

.cta {
  padding: 64px 0;
}

.cta__box {
  max-width: 490px;
  margin: 0 auto;
  border: 1px solid #26201a;
  border-top-right-radius: 300px;
  border-top-left-radius: 300px;
  padding: 40px 80px;
  text-align: center;
}

.cta__btns {
  margin-bottom: 40px;
  font-weight: 500;
}

.cta__btns-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.cta__btns p {
  letter-spacing: 0.02em;
}

.cta__links {
  padding: 0 13px;
}

.cta__links:hover .icon-social {
  fill: #ff9556;
}

.cta-list__item {
  position: relative;
  padding-top: 30px;
  letter-spacing: 0.02em;
}

.cta-list__item:not(:last-child) {
  margin-bottom: 24px;
}

.cta-list__item::before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url(../img/svg/star.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.about {
  padding: 59px 0;
  position: relative;
}

.about__wrapper {
  position: relative;
  padding-top: 100px;
}

.about__content {
  margin-left: 55px;
  max-width: 615px;
}

.about__box {
  max-width: 330px;
  transition-delay: 0.6s;
}

.about__box:first-child {
  max-width: 430px;
}

.about__box:not(:last-child) {
  margin-bottom: 64px;
}

.about__box:nth-child(even) {
  margin-left: auto;
  margin-right: 0;
}

.about__box p {
  letter-spacing: 0.02em;
}

.about__img {
  position: absolute;
  max-width: 456px;
  right: 40px;
  top: 40px;
  transition: opacity 1s cubic-bezier(0.17, 0.67, 0.55, 1.05), transform 1s cubic-bezier(0.17, 0.67, 0.55, 1.05);
}

.about__img img {
  aspect-ratio: auto 4/3;
  height: 100%;
}

.products {
  padding: 172px 0 10px 0;
}

.products__row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.products__col {
  flex: 0 0 518px;
  padding: 0 15px;
  margin-bottom: 167px;
  max-width: 50%;
}

.product-card {
  max-width: 488px;
  position: relative;
  border: 1px solid #26201a;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.product-card__body {
  padding: 95px 32px 0 32px;
}

.product-card__img {
  width: 140px;
  height: 140px;
  position: absolute;
  top: -70px;
  left: 50%;
  border-radius: 50%;
  overflow: hidden;
  transform: translate(-50%, 0);
}

.product-card__img img {
  height: 100%;
  aspect-ratio: auto 4/3;
  object-fit: cover;
  object-position: top;
}

.product-card__title {
  text-align: center;
  margin-bottom: 30px;
}

.product-card__title h4,
.product-card__title .title-4 {
  color: #ff9556;
}

.product-card__title p {
  font-weight: 500;
}

.product-card__list {
  font-size: 16px;
  line-height: 1.4;
  max-height: 200px;
  overflow: hidden;
  margin-bottom: 24px;
  transition: max-height 0.5s cubic-bezier(0.17, 0.67, 0.55, 1.05);
}

.product-card__list li {
  position: relative;
  padding-left: 26px;
}

.product-card__list li::before {
  content: "";
  width: 3px;
  height: 3px;
  background-color: #26201a;
  position: absolute;
  top: 9px;
  left: 12px;
  border-radius: 50%;
}

.product-card__list li:not(:last-child) {
  margin-bottom: 21px;
}

.product-card__more {
  display: flex;
  justify-content: center;
  margin-bottom: 45px;
}

.product-card__footer {
  text-align: center;
  margin-bottom: 26px;
}

.product-card__footer > p {
  font-family: "Raleway", sans-serif;
  opacity: 0.5;
  font-size: 16px;
}

.product-card__price {
  font-family: "Vetrino", sans-serif;
  font-size: 28px;
}

.product-card__price p {
  opacity: 1 !important;
}

.product-card__price p:first-child {
  font-family: "Vetrino", sans-serif;
  font-size: 28px;
  margin-bottom: 12px;
}

.product-card__price p:not(:first-child) {
  font-size: 16px;
  font-family: "Raleway", sans-serif;
}

.product-card__price span {
  font-size: 16px;
  font-family: "Raleway", sans-serif;
  opacity: 0.5;
}

.product-card__btn {
  padding: 0;
  border-top: 1px solid #26201a;
  height: 87px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.02em;
  width: 100%;
  transition: background-color 0.5s cubic-bezier(0.17, 0.67, 0.55, 1.05);
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-card__btn:hover {
  background-color: #ff9556;
}

/* Style the tab */

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.tab_lg {
  flex-wrap: wrap;
}

.tab_lg .tab__link {
  height: auto;
}

/* Style the buttons inside the tab */

.tab__link {
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 14px 6px 14px;
  text-align: center;
  color: #26201a;
  border: 1px solid #26201a;
  border-radius: 32px;
  height: 32px;
}

/* Create an active/current tablink class */

.tab__link.active {
  background-color: #ff9556;
  color: #ffffff;
  border-color: #ff9556;
}

/* Style the tab content */

.tab__content {
  display: none;
  padding-top: 32px;
  -webkit-animation: fadeEffect 1s;
  animation: fadeEffect 1s;
}

.tab__content p {
  opacity: 0.5;
}

.tab__content p:not(:last-child) {
  margin-bottom: 16px;
}

.tab__content.active {
  display: block;
}

/* Fade in tabs */

@-webkit-keyframes fadeEffect {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeEffect {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.testimonials {
  padding: 5px 0;
}

.testimonials__wrapper {
  position: relative;
}

.testimonials__title {
  position: absolute;
  max-width: 40%;
  top: 0;
  left: 0;
  z-index: 100;
}

.testimonials-slider {
  max-width: 488px;
  margin-right: 0;
  margin-left: auto;
  border: 1px solid #26201a;
}

.testimonials-slider__wrapper {
  position: relative;
}

.testimonials-slider__wrapper .swiper-pagination {
  font-family: "Vetrino";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  text-align: left;
}

.testimonials-slider__wrapper .swiper-button-prev,
.testimonials-slider__wrapper .swiper-button-next {
  position: static;
  display: flex;
  width: auto;
  height: auto;
  margin-top: 0;
}

.testimonials-slider__wrapper .swiper-button-prev::after,
.testimonials-slider__wrapper .swiper-button-next::after {
  display: none;
}

.testimonials-slider__wrapper .swiper-button-prev:hover .icon-arrow,
.testimonials-slider__wrapper .swiper-button-next:hover .icon-arrow {
  stroke: #ff9556;
}

.testimonials-slider__item img {
  height: 100%;
  aspect-ratio: auto 4/3;
}

.testimonials-slider__btns {
  position: absolute;
  bottom: 13px;
  left: 23%;
  display: flex;
  align-items: center;
  height: 32px;
  gap: 24px;
}

.materials {
  padding: 190px 0 100px 0;
}

.materials__title {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 64px;
}

.materials__title h2 {
  position: relative;
  display: inline;
}

.materials__title h2::before {
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  right: 100%;
  top: -30px;
  background-image: url(../img/svg/stars.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.materials__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.materials__box {
  text-align: center;
  flex: 0 0 33.333%;
  max-width: 270px;
  font-size: 16px;
  padding: 0 15px;
  transition: color 0.5s cubic-bezier(0.17, 0.67, 0.55, 1.05);
  font-weight: 500;
}

.materials__box:hover {
  color: #ff9556;
}

.materials__box p {
  letter-spacing: 0.02em;
}

.materials__box img {
  width: 48px;
  margin-bottom: 8px;
  margin: 0 auto 8px auto;
  aspect-ratio: auto 4/3;
}

.faq {
  padding: 55px 0 150px;
}

.faq__title {
  text-align: center;
  margin-bottom: 72px;
}

.accordion {
  max-width: 640px;
  margin: 0 auto;
}

.accordion__item {
  border-bottom: 1px solid #26201a;
}

.accordion__item.active .plus_lg::after {
  transform: translate(-50%, -50%) rotate(90deg);
}

.accordion__btn {
  color: #26201a;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  transition: 0.5s;
  padding: 20px 30px 20px 0;
  font-weight: 500;
  position: relative;
}

.accordion__panel {
  padding: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0.17, 0.67, 0.55, 1.05), opacity 0.5s cubic-bezier(0.17, 0.67, 0.55, 1.05);
}

.accordion__panel p {
  margin-bottom: 20px;
}

.bg {
  background-color: #f5f4f4;
}

.mentoring {
  font-size: 14px;
  color: #26201a;
}

.mentoring p,
.mentoring li {
  line-height: 1.4em;
}

.mentoring-wrapper {
  padding: 48px 16px 16px 16px;
  max-width: 1600px;
  margin: 0 auto;
}

.mentoring-wrapper .header__btn {
  top: -34px;
}

.mentoring h1,
.mentoring .title-1,
.mentoring .title-2 {
  font-size: 47px;
  line-height: 0.95em;
  text-transform: uppercase;
  color: #26201a;
}

.mentoring h2 {
  font-size: 26px;
  line-height: 1.1em;
  text-transform: uppercase;
  color: #ff9556;
}

.mentoring h3,
.mentoring .title-3 {
  font-size: 26px;
  line-height: 1.1em;
}

.mentoring h4,
.mentoring .title-4 {
  font-size: 26px;
  line-height: 1.1em;
}

.mentoring h5,
.mentoring .title-5 {
  line-height: 1.3em;
  font-size: 18px;
}

.mentoring h6,
.mentoring .title-6 {
  font-size: 32px;
  line-height: 1.3em;
}

.mentoring-hero {
  position: relative;
  margin-bottom: 60px;
  overflow: hidden;
}

.mentoring-hero__image {
  position: absolute;
  left: 0;
}

.mentoring-hero__wrapper {
  padding: 16px;
  background-color: rgba(38, 32, 26, 0.2);
  border-radius: 24px;
  overflow: hidden;
  margin-bottom: 8px;
  position: relative;
  min-height: 100vw;
}

.mentoring-hero__row {
  display: flex;
  justify-content: space-between;
}

.mentoring-hero__content .btn {
  position: relative;
}

.mentoring-hero__title {
  display: flex;
  flex-wrap: wrap;
  max-width: 820px;
  margin-bottom: 16px;
}

.mentoring-hero__subtitle {
  margin-bottom: 65px;
  position: relative;
}

.mentoring-hero__blocks {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.mentoring-hero__blocks p {
  line-height: 1.5em;
}

.mentoring-hero__block {
  padding: 24px;
  border-radius: 24px;
  overflow: hidden;
  flex: 0 0 100%;
  height: 190px;
}

.mentoring-hero__block:not(:last-child) {
  margin-bottom: 8px;
}

.mentoring-hero__block:first-child {
  text-transform: uppercase;
  background-color: #ffffff;
  background-image: url(../img/block-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  font-weight: 500;
}

.mentoring-hero__block:first-child p:not(:last-child) {
  margin-bottom: 12px;
}

.mentoring-hero__block:first-child p:nth-child(even) {
  text-align: right;
}

.mentoring-hero__block:nth-child(2) {
  background-color: #ff9556;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mentoring-hero__block:nth-child(2) img {
  width: 35px;
}

.mentoring-hero__block:last-child {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #ffffff;
  background-image: url(../img/block-3.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.mentoring-hero__block:last-child p {
  margin-bottom: 0;
  margin-top: auto;
}

.mentoring h5,
.mentoring .title-5 {
  margin-bottom: 24px;
}

.mentoring_double-text p {
  margin-bottom: 24px;
}

.mentoring__text {
  margin-bottom: 60px;
}

.mentoring__text__title {
  max-width: 600px;
}

.mentoring-benefits {
  padding: 16px;
  border-radius: 24px;
  background-color: #ffffff;
  position: relative;
  margin-bottom: 60px;
}

.mentoring-benefits::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../img/bg.png);
  background-position: 130% bottom;
  background-repeat: no-repeat;
  filter: grayscale(1);
  opacity: 0.8;
}

.mentoring-benefits h3,
.mentoring-benefits .title-3 {
  text-transform: uppercase;
  color: #bebcba;
}

.mentoring-benefits__title {
  margin-bottom: 24px;
}

.mentoring-benefits__content ul {
  width: 100%;
}

.mentoring-benefits__content ul li {
  max-width: 540px;
  position: relative;
  padding-left: 20px;
}

.mentoring-benefits__content ul li:not(:last-child) {
  margin-bottom: 16px;
}

.mentoring-benefits__content ul li::before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 8px;
  left: 7px;
  background-color: #26201a;
  border-radius: 50%;
}

.mentoring-result {
  margin-bottom: 60px;
}

.mentoring-result__title {
  position: relative;
  display: inline-block;
}

.mentoring-result__title .heart {
  position: absolute;
  right: -13%;
  top: -64%;
  z-index: -1;
  width: 50px;
  height: 50px;
}

.mentoring-result__title .heart svg {
  width: 100%;
}

.mentoring-result__title h4,
.mentoring-result__title .title-4 {
  margin-bottom: 32px;
}

.mentoring-result__content ul {
  width: 100%;
}

.mentoring-result__content ul li {
  max-width: 540px;
  position: relative;
  padding-left: 20px;
}

.mentoring-result__content ul li:not(:last-child) {
  margin-bottom: 16px;
}

.mentoring-result__content ul li::before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 8px;
  left: 7px;
  background-color: #26201a;
  border-radius: 50%;
}

.mentoring-step {
  margin-bottom: 8px;
}

.mentoring-step .mentoring__row {
  align-items: flex-start;
}

.mentoring-step__content {
  padding: 16px 0;
  border-radius: 24px;
  background-color: #ffffff;
  margin-bottom: 8px;
}

.mentoring-step__content h5 span,
.mentoring-step__content .title-5 span {
  color: #ff9556;
}

.mentoring-step__content p:not(:last-child) {
  margin-bottom: 16px;
}

.mentoring-step__scroll {
  padding: 0 16px;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}

.mentoring-step__scroll::-webkit-scrollbar {
  width: 3px;
}

.mentoring-step__scroll::-webkit-scrollbar-track {
  background: #ff9556;
  background: transparent;
}

.mentoring-step__scroll::-webkit-scrollbar-thumb {
  background: #ff9556;
  border-radius: 3px;
}

.mentoring-step__scroll::-webkit-scrollbar-thumb:hover {
  background: #ff9556;
}

.mentoring-step__media {
  background-color: #ff9556;
  padding: 20px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mentoring-step__media img {
  max-width: 90%;
  margin: 0 auto;
}

.mentoring-cta {
  padding: 16px;
  border-radius: 24px;
  background-color: #ffffff;
  background-image: url(../img/bg-cta.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 60px;
}

.mentoring-cta__title {
  margin-bottom: 24px;
}

.mentoring-cta__content p span {
  color: #ff9556;
}

.mentoring-cta__content p:not(:last-child) {
  margin-bottom: 16px;
}

.mentoring-program {
  margin-bottom: 60px;
}

.mentoring-program__header {
  margin-bottom: 24px;
}

.mentoring-program__title {
  margin-bottom: 24px;
  max-width: 80%;
}

.mentoring-program .accordion {
  max-width: 100%;
}

.mentoring-program .accordion__btn {
  padding: 16px 0;
  font-weight: 600;
  line-height: 1.4em;
}

.mentoring-program .accordion__btn p {
  padding-right: 30px;
  font-size: 14px;
}

.mentoring-program .accordion__btn span {
  font-family: "Vetrino", sans-serif;
  font-size: 18px;
  color: #bebcba;
  margin-bottom: 10px;
  display: inline-block;
}

.mentoring-program .accordion__panel ol {
  padding: 20px 0;
  width: 100%;
  list-style: none;
  counter-reset: my-awesome-counter;
}

.mentoring-program .accordion__panel ol li {
  max-width: 540px;
  position: relative;
  padding-left: 20px;
  counter-increment: my-awesome-counter;
}

.mentoring-program .accordion__panel ol li:not(:last-child) {
  margin-bottom: 16px;
}

.mentoring-program .accordion__panel ol li::before {
  content: counter(my-awesome-counter) ". ";
  left: 0;
  position: absolute;
}

.mentoring-program .accordion__item {
  border-color: #bebcba;
}

.mentoring-program .accordion__item.active {
  border-color: #ff9556;
}

.mentoring-program .accordion__item.active .accordion__btn {
  color: #ff9556;
}

.mentoring-program__bonus {
  padding: 20px 0;
}

.mentoring-program__bonus span {
  font-family: "Vetrino", sans-serif;
  font-size: 18px;
  color: #ff9556;
  margin-bottom: 10px;
  display: inline-block;
}

.mentoring-program__bonus p {
  font-size: 16px;
  font-weight: 600;
}

.mentoring-program__bonus p:not(:last-child) {
  margin-bottom: 16px;
}

.mentoring-get {
  margin-bottom: 60px;
}

.mentoring-get__header p:not(:last-child) {
  margin-bottom: 16px;
}

.mentoring-get__title {
  margin-bottom: 24px;
  max-width: 80%;
}

.mentoring-get__title h4,
.mentoring-get__title .title-4 {
  margin-bottom: 16px;
}

.mentoring-get__title p {
  color: #ff9556;
}

.mentoring-get .accordion {
  max-width: 100%;
}

.mentoring-get .accordion__btn {
  font-size: 14px;
  padding: 16px 0;
  font-weight: 600;
  line-height: 1.4em;
}

.mentoring-get .accordion__btn p {
  padding-right: 30px;
}

.mentoring-get .accordion__btn span {
  font-family: "Vetrino", sans-serif;
  font-size: 18px;
  color: #bebcba;
  margin-bottom: 10px;
  display: inline-block;
}

.mentoring-get .accordion__panel ol {
  padding: 20px 0;
  width: 100%;
  list-style: none;
  counter-reset: my-awesome-counter;
}

.mentoring-get .accordion__panel ol li {
  max-width: 540px;
  position: relative;
  padding-left: 20px;
  counter-increment: my-awesome-counter;
}

.mentoring-get .accordion__panel ol li:not(:last-child) {
  margin-bottom: 16px;
}

.mentoring-get .accordion__panel ol li::before {
  content: counter(my-awesome-counter) ". ";
  left: 0;
  position: absolute;
}

.mentoring-get .accordion__panel ul {
  width: 100%;
  padding-bottom: 20px;
}

.mentoring-get .accordion__panel ul li {
  max-width: 540px;
  position: relative;
  padding-left: 20px;
}

.mentoring-get .accordion__panel ul li:not(:last-child) {
  margin-bottom: 8px;
}

.mentoring-get .accordion__panel ul li::before {
  content: "";
  width: 3px;
  height: 3px;
  position: absolute;
  top: 8px;
  left: 7px;
  background-color: #26201a;
  border-radius: 50%;
}

.mentoring-get .accordion__item {
  border-color: #bebcba;
}

.mentoring-get .accordion__item.active {
  border-color: #ff9556;
}

.mentoring-get .accordion__item.active .accordion__btn {
  color: #ff9556;
}

.mentoring-get__bonus {
  padding: 20px 0;
  font-weight: 600;
}

.mentoring-about {
  background-color: #ff9556;
  padding: 16px;
  border-radius: 24px;
  position: relative;
}

.mentoring-about__wrapper {
  position: relative;
}

.mentoring-about::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(../img/about-bg.png);
  background-position: 10% bottom;
  background-repeat: no-repeat;
  filter: grayscale(1);
  opacity: 0.8;
}

.mentoring-about__title {
  max-width: 440px;
}

.mentoring-about__title .btn {
  display: none;
}

.mentoring-about__title h5,
.mentoring-about__title .title-5 {
  margin-bottom: 24px;
}

.mentoring-about__content p {
  line-height: 1.4em;
}

.mentoring-about__content p:not(:last-child) {
  margin-bottom: 16px;
}

.mentoring .footer {
  padding-bottom: 0;
}

.mentoring .footer::before {
  display: none;
}

.mentoring .footer .cta__links:hover svg {
  fill: #ff9556;
}

@media only screen and (min-width: 480px) {
  .mentoring h1,
  .mentoring .title-1,
  .mentoring .title-2 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 580px) {
  .mentoring h1,
  .mentoring .title-1,
  .mentoring .title-2 {
    font-size: 70px;
  }
}

@media only screen and (min-width: 680px) {
  .mentoring h1,
  .mentoring .title-1,
  .mentoring .title-2 {
    font-size: 80px;
  }
}

@media only screen and (min-width: 766px) {
  .mentoring h1,
  .mentoring .title-1,
  .mentoring .title-2 {
    font-size: 100px;
  }
}

@media only screen and (min-width: 768px) {
  .header__btn span {
    opacity: 1;
  }

  .mentoring h5,
  .mentoring .title-5 {
    font-size: 24px;
  }

  .mentoring-hero__block {
    flex: 0 0 32%;
  }
}

@media only screen and (min-width: 860px) {
  .hero__row {
    margin-bottom: 0;
  }

  .hero__btns.anim-top_lg.in-view {
    transform: translateY(-23px);
  }

  .mentoring h2 {
    font-size: 40px;
  }

  .mentoring h3,
  .mentoring .title-3 {
    font-size: 40px;
  }

  .mentoring h4,
  .mentoring .title-4 {
    font-size: 32px;
  }

  .mentoring h5,
  .mentoring .title-5 {
    font-size: 32px;
  }

  .mentoring-hero__wrapper {
    margin-bottom: 0;
    padding: 24px;
    min-height: 70vw;
  }

  .mentoring-hero__title {
    margin-bottom: 24px;
  }

  .mentoring-hero__blocks {
    position: absolute;
    top: 24px;
    right: 24px;
    bottom: 24px;
    flex-direction: column;
  }

  .mentoring-hero__block {
    width: 225px;
  }

  .mentoring-hero__block:not(:last-child) {
    margin-right: 0;
  }

  .mentoring__row {
    display: flex;
    justify-content: space-between;
    margin: 0 -12px;
  }

  .mentoring__col {
    flex: 0 0 50%;
    padding: 0 12px;
  }

  .mentoring_double-text {
    display: flex;
    justify-content: space-between;
    column-gap: 50px;
  }

  .mentoring-benefits {
    padding: 24px;
  }

  .mentoring-benefits__content ul {
    column-count: 2;
  }

  .mentoring-result__title .heart {
    width: 100px;
    height: 100px;
    right: -23%;
    top: -54%;
  }

  .mentoring-step {
    margin-bottom: 24px;
  }

  .mentoring-step__content {
    padding: 24px 0;
  }

  .mentoring-step__scroll {
    height: 480px;
    overflow: auto;
  }

  .mentoring-step__scroll {
    padding: 0 24px;
  }

  .mentoring-step__media {
    padding: 40px;
    height: 528px;
  }

  .mentoring-cta {
    padding: 24px;
  }

  .mentoring-cta__title {
    margin-bottom: 0;
    max-width: 50%;
  }

  .mentoring-program__header {
    margin-bottom: 40px;
  }

  .mentoring-program .accordion__btn p {
    font-size: 16px;
  }

  .mentoring-program .accordion__btn {
    font-size: 16px;
    padding: 20px 0;
  }

  .mentoring-program .accordion__btn span {
    font-size: 24px;
  }

  .mentoring-program__bonus span {
    font-size: 24px;
  }

  .mentoring-get .accordion__btn {
    font-size: 16px;
    padding: 20px 0;
  }

  .mentoring-get .accordion__btn span {
    font-size: 24px;
  }

  .mentoring-about__title .btn {
    display: inline-block;
  }

  .mentoring-about__title h5,
  .mentoring-about__title .title-5 {
    margin-bottom: 40px;
  }

  .mentoring-about__content p {
    font-size: 16px;
  }

  .mentoring-about__content .btn {
    display: none;
  }
}

@media only screen and (min-width: 968px) {
  .mentoring h1,
  .mentoring .title-1,
  .mentoring .title-2 {
    font-size: 110px;
  }

  .mentoring h2 {
    font-size: 60px;
  }

  .mentoring h3,
  .mentoring .title-3 {
    font-size: 50px;
  }

  .mentoring h4,
  .mentoring .title-4 {
    font-size: 46px;
  }
}

@media only screen and (min-width: 1024px) {
  .mentoring {
    font-size: 16px;
  }

  .mentoring-wrapper {
    padding: 48px 24px 24px 24px;
  }

  .mentoring-hero {
    margin-bottom: 80px;
  }

  .mentoring-hero__wrapper {
    min-height: auto;
  }

  .mentoring-hero__title {
    margin-bottom: 40px;
  }

  .mentoring-hero__title h1,
  .mentoring-hero__title .title-1,
  .mentoring-hero__title .title-2 {
    margin-bottom: 20px;
  }

  .mentoring__text {
    margin-bottom: 80px;
  }

  .mentoring-benefits {
    margin-bottom: 80px;
  }

  .mentoring-benefits__title {
    margin-bottom: 40px;
  }

  .mentoring-benefits__content ul li:not(:last-child) {
    margin-bottom: 24px;
  }

  .mentoring-result {
    margin-bottom: 80px;
  }

  .mentoring-result__title .heart {
    right: -8%;
    top: -24%;
  }

  .mentoring-result__content ul li:not(:last-child) {
    margin-bottom: 24px;
  }

  .mentoring-step__content {
    margin-bottom: 24px;
  }

  .mentoring-step__content p:not(:last-child) {
    margin-bottom: 24px;
  }

  .mentoring-cta {
    margin-bottom: 80px;
  }

  .mentoring-cta__content p:not(:last-child) {
    margin-bottom: 24px;
  }

  .mentoring-program {
    margin-bottom: 80px;
  }

  .mentoring-program .accordion__btn span {
    font-size: 32px;
  }

  .mentoring-program .accordion__panel ol li:not(:last-child) {
    margin-bottom: 24px;
  }

  .mentoring-program__bonus span {
    font-size: 32px;
  }

  .mentoring-program__bonus p:not(:last-child) {
    margin-bottom: 24px;
  }

  .mentoring-get {
    margin-bottom: 80px;
  }

  .mentoring-get .accordion__btn span {
    font-size: 32px;
  }

  .mentoring-get .accordion__panel ol li:not(:last-child) {
    margin-bottom: 24px;
  }
}

@media only screen and (min-width: 1280px) {
  .mentoring-wrapper {
    padding: 48px 32px 32px 32px;
  }

  .mentoring h1,
  .mentoring .title-1,
  .mentoring .title-2 {
    font-size: 120px;
  }

  .mentoring h2 {
    font-size: 80px;
  }

  .mentoring h3,
  .mentoring .title-3 {
    font-size: 60px;
  }

  .mentoring h4,
  .mentoring .title-4 {
    font-size: 52px;
  }

  .mentoring-hero {
    margin-bottom: 100px;
  }

  .mentoring-hero__image {
    top: 40px;
    left: -40px;
  }

  .mentoring__text {
    margin-bottom: 100px;
  }

  .mentoring-benefits {
    margin-bottom: 100px;
    padding: 24px;
  }

  .mentoring-result {
    margin-bottom: 100px;
  }

  .mentoring-cta {
    padding: 50px;
    margin-bottom: 100px;
  }

  .mentoring-program {
    margin-bottom: 100px;
  }

  .mentoring-program__header {
    margin-bottom: 60px;
  }

  .mentoring-get {
    margin-bottom: 100px;
  }

  .mentoring-about {
    padding: 24px;
  }

  .mentoring-about__title h5,
  .mentoring-about__title .title-5 {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 1440px) {
  .header__btn {
    font-size: 18px;
  }

  .mentoring-wrapper {
    padding: 60px 48px 48px 48px;
  }

  .mentoring-wrapper .header__btn {
    top: -43px;
  }

  .mentoring h1,
  .mentoring .title-1,
  .mentoring .title-2 {
    font-size: 155px;
  }

  .mentoring h2 {
    font-size: 94px;
  }

  .mentoring h3,
  .mentoring .title-3 {
    font-size: 84px;
  }

  .mentoring h4,
  .mentoring .title-4 {
    font-size: 56px;
  }

  .mentoring-hero {
    margin-bottom: 120px;
  }

  .mentoring-hero__title {
    margin-bottom: 60px;
  }

  .mentoring__text {
    margin-bottom: 120px;
  }

  .mentoring-benefits {
    margin-bottom: 120px;
  }

  .mentoring-benefits__title {
    margin-bottom: 60px;
  }

  .mentoring-benefits__content ul li:not(:last-child) {
    margin-bottom: 32px;
  }

  .mentoring-result {
    margin-bottom: 120px;
  }

  .mentoring-result__content ul li:not(:last-child) {
    margin-bottom: 32px;
  }

  .mentoring-step__content p:not(:last-child) {
    margin-bottom: 32px;
  }

  .mentoring-cta {
    margin-bottom: 120px;
  }

  .mentoring-cta__content p:not(:last-child) {
    margin-bottom: 32px;
  }

  .mentoring-program {
    margin-bottom: 120px;
  }

  .mentoring-program__header {
    margin-bottom: 80px;
  }

  .mentoring-program .accordion__btn p {
    font-size: 18px;
  }

  .mentoring-program .accordion__btn {
    padding: 32px 0;
  }

  .mentoring-program .accordion__panel ol li:not(:last-child) {
    margin-bottom: 32px;
  }

  .mentoring-program__bonus {
    padding: 32px 0;
  }

  .mentoring-program__bonus p {
    font-size: 18px;
  }

  .mentoring-program__bonus p:not(:last-child) {
    margin-bottom: 32px;
    font-size: 18px;
  }

  .mentoring-get {
    margin-bottom: 120px;
  }

  .mentoring-get__header p:not(:last-child) {
    margin-bottom: 24px;
  }

  .mentoring-get__title h4,
  .mentoring-get__title .title-4 {
    margin-bottom: 24px;
  }

  .mentoring-get .accordion__btn {
    padding: 32px 0;
    font-size: 18px;
  }

  .mentoring-get .accordion__panel ol li:not(:last-child) {
    margin-bottom: 32px;
  }

  .mentoring-get .accordion__panel ul {
    padding-bottom: 24px;
  }

  .mentoring-get__bonus {
    padding: 32px 0;
    font-size: 18px;
  }

  .mentoring-about {
    padding: 56px;
  }

  .mentoring-about__title h5,
  .mentoring-about__title .title-5 {
    margin-bottom: 80px;
  }

  .mentoring-about__content p {
    font-size: 18px;
  }

  .mentoring-about__content p:not(:last-child) {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 992px) {
  .product-card__body {
    padding: 90px 15px 0 15px;
  }

  .testimonials-slider__btns {
    left: 15%;
  }

  .materials {
    padding: 100px 0;
  }

  .faq {
    padding: 50px 0 100px;
  }

  .accordion__item {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  body {
    font-size: 16px;
  }

  h1,
  .title-2,
  .title-1,
  h2 {
    font-size: 36px;
  }

  .anim-top_lg.in-view {
    transform: translateY(-23px);
  }

  .about__img.in-view {
    transform: translate(-50%, 0);
  }

  .footer__social {
    margin-bottom: 20px;
  }

  .cta {
    padding: 0 0 30px 0;
  }

  .cta__box {
    padding: 40px;
  }

  .about {
    padding: 50px 0;
  }

  .about__content {
    max-width: 100%;
    margin-left: 0;
  }

  .about__box {
    max-width: 100%;
    text-align: center;
  }

  .about__box:first-child {
    max-width: 100%;
  }

  .about__box:not(:last-child) {
    margin-bottom: 48px;
  }

  .about__img {
    right: auto;
    top: 0;
    max-width: 50%;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .products {
    padding: 100px 0 10px 0;
  }

  .products__row {
    justify-content: center;
  }

  .products__col {
    max-width: 100%;
    margin-bottom: 130px;
  }

  .product-card__list li {
    padding-left: 10px;
  }

  .product-card__list li::before {
    left: 0;
  }

  .testimonials__title {
    max-width: 100%;
    position: static;
    text-align: center;
    margin-bottom: 30px;
  }

  .testimonials-slider {
    margin: 0 auto;
    max-width: 100%;
  }

  .testimonials-slider__wrapper .swiper-pagination {
    position: static;
  }

  .testimonials-slider__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;
  }

  .testimonials-slider__btns {
    position: static;
  }

  .materials {
    padding: 90px 0 20px 0;
  }

  .materials__row {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .materials__box {
    margin-bottom: 32px;
  }

  .faq {
    padding: 50px 0 70px;
  }

  .faq__title {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 360px) {
  h1,
  .title-2,
  .title-1,
  h2 {
    font-size: 26px;
  }

  .product-card__img {
    width: 108px;
    height: 108px;
  }

  .product-card__price p:first-child {
    font-size: 18px;
  }
}

@media only screen and (max-width: 340px) {
  body {
    font-size: 14px;
  }

  h3,
  .title-3 {
    font-size: 28px;
    margin-bottom: 16px;
  }
}